var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-left"},[_c('div',[_c('div',{staticClass:"text-label"},[_vm._v("Email Address")]),_c('v-text-field',{attrs:{"outlined":"","type":"email","error-messages":_vm.showErrors.email && _vm.errorMessages.email ? _vm.errorMessages.email : null},on:{"blur":function($event){(_vm.showErrors.email = true), _vm.cleanEmailDomain(), _vm.checkEmailSuggestion()},"focus":function($event){(_vm.showErrors.email = false), (_vm.emailSuggestion = null)}},model:{value:(_vm.local.email),callback:function ($$v) {_vm.$set(_vm.local, "email", $$v)},expression:"local.email"}}),_c('v-expand-transition',[(_vm.emailSuggestion?.full)?_c('v-alert',{attrs:{"color":"orange","outlined":"","type":"info"}},[_vm._v(" Did you mean "),_c('button',{staticClass:"font-weight-bold text-decoration-underline",on:{"click":function($event){(_vm.local.email = _vm.emailSuggestion.full), (_vm.emailSuggestion = null)}}},[_vm._v(" @"+_vm._s(_vm.emailSuggestion.domain)+" ")]),_vm._v(" ? ")]):_vm._e()],1)],1),_c('div',[_c('div',{staticClass:"text-label"},[_vm._v("Password")]),_c('v-text-field',{attrs:{"outlined":"","type":"password","error-messages":_vm.showErrors.password && _vm.errorMessages.password
          ? _vm.errorMessages.password
          : null},on:{"blur":function($event){_vm.showErrors.password = true},"focus":function($event){_vm.showErrors.password = false}},model:{value:(_vm.local.password),callback:function ($$v) {_vm.$set(_vm.local, "password", $$v)},expression:"local.password"}})],1),_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"text-label"},[_vm._v("Confirm Password")]),_c('v-text-field',{attrs:{"outlined":"","type":"password","error-messages":_vm.showErrors.confirmPassword && _vm.errorMessages.confirmPassword
          ? _vm.errorMessages.confirmPassword
          : null},on:{"blur":function($event){_vm.showErrors.confirmPassword = true},"focus":function($event){_vm.showErrors.confirmPassword = false}},model:{value:(_vm.local.confirmPassword),callback:function ($$v) {_vm.$set(_vm.local, "confirmPassword", $$v)},expression:"local.confirmPassword"}})],1),_c('div',{staticClass:"mb-4"},[_c('v-checkbox',{staticClass:"mb-4",attrs:{"error-messages":_vm.showErrors.accept && _vm.errorMessages.accept
          ? _vm.errorMessages.accept
          : null},model:{value:(_vm.local.accept),callback:function ($$v) {_vm.$set(_vm.local, "accept", $$v)},expression:"local.accept"}},[_c('template',{slot:"label"},[_c('span',{staticClass:"black--text"},[_vm._v("I have read, understood and accept the "),_c('a',{attrs:{"href":"https://www.willed.com.au/privacy-policy","target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v("Privacy Policy")]),_vm._v(" and "),_c('a',{attrs:{"href":"https://www.willed.com.au/platform-terms-and-conditions","target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v("Willed Platform Terms and Conditions")])])])],2)],1),_c('div',{staticClass:"text-center"},[_c('v-btn',_vm._b({attrs:{"x-large":"","loading":_vm.processing},on:{"click":_vm.register}},'v-btn',_vm.largeButton,false),[_vm._v(" Register ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }