const fullDomainCorrections = {
  'gmail.co': 'gmail.com',
  'gmail.com.au': 'gmail.com',
  'gmail.gmail.com': 'gmail.com',
  'gmail.com.com': 'gmail.com',
  'g.mail.com': 'gmail.com',
  'icloud.com.au': 'icloud.com',
  'willed.com': 'willed.com.au',
  'icloud.co': 'icloud.com',
  'bigpond.co': 'bigpond.com',
  'iinet.au': 'iinet.net.au',
  'iinet.net': 'iinet.net.au',
}
const partDomainCorrections = {
  optisnet: 'optusnet',
  wedtnet: 'westnet',
  tahoo: 'yahoo',
  icloid: 'icloud',
  icoud: 'icloud',
  yanoo: 'yahoo',
  yshoo: 'yahoo',
  outloo: 'outlook',
  outook: 'outlook',
  oulook: 'outlook',
  outllo: 'outlook',
  outlool: 'outlook',
  outliok: 'outlook',
  putlook: 'outlook',
  ouook: 'outlook',
  hotnail: 'hotmail',
  hotmai: 'hotmail',
  hoymail: 'hotmail',
  hormail: 'hotmail',
  hptmail: 'hotmail',
  htomail: 'hotmail',
  hotmaiil: 'hotmail',
  gotmail: 'hotmail',
  hotmaill: 'hotmail',
  hotail: 'hotmail',
  homail: 'hotmail',
  homtail: 'hotmail',
  hotmsil: 'hotmail',
  hitmail: 'hotmail',
  hotmil: 'hotmail',
  bigond: 'bigpond',
  bigoond: 'bigpond',
  bogpond: 'bigpond',
  bigpon: 'bigpond',
  bigppond: 'bigpond',
  gial: 'gmail',
  gmaol: 'gmail',
  gmil: 'gmail',
  gmsil: 'gmail',
  gmal: 'gmail',
  gmaill: 'gmail',
  gmai: 'gmail',
  gmial: 'gmail',
  gamil: 'gmail',
  gnail: 'gmail',
  hmail: 'gmail',
  con: 'com',
  comb: 'com',
  coms: 'com',
  vom: 'com',
  comn: 'com',
  comh: 'com',
  comr: 'com',
  comk: 'com',
  clm: 'com',
  xom: 'com',
  su: 'au',
  comi: 'com',
  comm: 'com',
  coml: 'com',
  gom: 'com',
  comj: 'com',
  auj: 'au',
  cm: 'com',
  comc: 'com',
  ai: 'au',
  col: 'com',
  aus: 'aus',
  comau: 'com.au',
  coma: 'com',
  ccom: 'com',
  aju: 'au',
  fom: 'com',
  cpm: 'com',
  comv: 'com',
  comp: 'com',
  cam: 'com',
  cim: 'com',
  come: 'com',
  cok: 'com',
  comw: 'com',
  comq: 'com',
  qu: 'au',
  comg: 'com',
  wdu: 'edu',
  colm: 'com',
  lcom: 'com',
  comiii: 'com',
  aui: 'au',
  comd: 'com',
  aul: 'au',
  ay: 'au',
  edi: 'edu',
  coom: 'com',
  aut: 'au',
  com: 'com',
}

export { partDomainCorrections, fullDomainCorrections }
